import React from 'react'

const IntroContentHalf = ({ title, subtitle, content, image }) => (
  <div className='intro-content'>
    <div className='intro-content__half'>
      <div className='intro-content__text'>
        <h1 dangerouslySetInnerHTML={{__html:title.split(' ').join('<br /> ') }} />
        <h2>{ subtitle }</h2>
        <div dangerouslySetInnerHTML={{__html: content }} />
      </div>
    </div>
    <div className='intro-content__half' style={{backgroundImage: `url(${image && image.localFile.childImageSharp.original.src})`}}>
    </div>
  </div>
)

IntroContentHalf.defaultProps = {
  title: '',
  subtitle: '',
  content: '',
  image: null,
}

export default IntroContentHalf
