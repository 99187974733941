import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

class LHSImage extends Component {
  render() {
    let { title, content, link_text, link_url, image } = this.props

    let bgSrc = `url(${image && image.localFile.childImageSharp.original.src})`

    return (
      <div className='lhs-image'>
        <div className='lhs-image__image' style={{backgroundImage: bgSrc}} />
        <div className='lhs-image__content'>
        <Fade right distance="40px">
          <h2>More — <br />{ title }</h2>
          <div dangerouslySetInnerHTML={{__html: content }} />
          { link_url && <Link to={link_url} className='btn'>More — { link_text }</Link> }
        </Fade>
        </div>
      </div>
    )
  }
}

LHSImage.defaultProps = {
  title: '',
  content: '',
  link_text: '',
  link_url: '',
  image: null,
}

export default LHSImage
