import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Transition } from 'react-transition-group'

import { Facebook, Instagram, LinkedIn, Youtube, Twitter, Pinterest } from './icons'
import PropertySearch from './property-search'
import PropertyStats from './property-stats'

const title = (text) => `More —<br /> ${text.split(' ').join('<br /> ')}`

class Splash extends Component {
  state = {
    slider: 0,
  }

  componentDidMount() {
    if (! this.props.homepage) return
    this.interval = setInterval(() => {
      let len = this.props.data.properties.edges.length
      let { slider } = this.state
      if (++slider >= len) slider = 0
      this.setState({ slider })
    }, 8000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  _renderBlocks() {
    const duration = 400;

    const defaultStyle = {
      transition: `opacity ${duration}ms linear`,
      opacity: 0,
      visibility: 'hidden',
    }

    const transitionStyles = {
      entering: { opacity: 0, visibility: 'visible' },
      entered:  { opacity: 1, visibility: 'visible' },
      exiting: { opacity: 0, visibility: 'visible' },
    }

    return this.props.data.properties.edges.map((edge, i) => {
      let { rex_data } = edge.node.acf

      let props = {
        bedrooms: rex_data.attributes.bedrooms,
        bathrooms: rex_data.attributes.bathrooms,
        total_car_accom: rex_data.attributes.total_car_accom,
      }

      return (
        <Transition in={this.state.slider === i} timeout={duration} key={i}>
          { state => (
            <div className={`splash__block__property`} style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}>
              <Link to={`/property/${edge.node.slug}/`}>
                <figure>
                  <img src={rex_data.images && rex_data.images[0].url} alt={props.title} />
                </figure>
                <figcaption>
                  <h4>{ rex_data.advert_internet.heading }</h4>
                  <h5>{ rex_data.address.formats.street_name_number_w_suburb }</h5>
                  <div className='info'>
                    <p>{ rex_data.price_advertise_as }</p>
                    <PropertyStats color={'#ffffff'} {...props} />
                  </div>
                </figcaption>
              </Link>
            </div>
          ) }
        </Transition>
      )
    })
  }

  render() {
    let { heading, homepage } = this.props
    let { options } = this.props.data.allWordpressAcfOptions.edges[0].node

    let bgSrc = `url(https://picsum.photos/1920/1080/?random=${Math.random()})`
    let choice = {title: ''}
    if (heading.length) {
      choice = heading[Math.floor(Math.random() * heading.length)]
      let image = choice.background && choice.background.localFile.childImageSharp.original.src
      if (image) {
        bgSrc = `url(${image})`
      }
    }

    return (
      <div className='splash' style={{backgroundImage: bgSrc}}>

        <div className='splash__inner'>
          <div className='splash__heading' dangerouslySetInnerHTML={{__html: title(choice.title)}} />

          <div className={`splash__block ${!homepage ? 'splash__block--square' : ''}`}>
            { homepage ? this._renderBlocks() : (
              <div className='splash__block__cta'>
                <h2>More —<br /> Information for people looking to sell.</h2>
                <ul>
                  <li><Link to='/sell-with-more'>Sell with — More</Link></li>
                  <li><Link to='/contact'>Get an Appraisal</Link></li>
                  <li><Link to='/contact'>Seller's e-book</Link></li>
                  <li><Link to='/contact'>Monthly Marketing Stats</Link></li>
                  <li><Link to='/about'>More — Team</Link></li>
                </ul>
              </div>
            )}
          </div>

          { homepage &&
            <div className='splash__search'>
              <PropertySearch />
            </div>
          }
        </div>

        <div className='splash__social'>
          { options.facebook && <a href={ options.facebook } target='_blank' rel='nofollow noopener noreferrer'><Facebook /></a> }
          { options.instagram && <a href={ options.instagram } target='_blank' rel='nofollow noopener noreferrer'><Instagram /></a> }
          { options.linkedin && <a href={ options.linkedin } target='_blank' rel='nofollow noopener noreferrer'><LinkedIn /></a> }
          { options.youtube && <a href={ options.youtube } target='_blank' rel='nofollow noopener noreferrer'><Youtube /></a> }
          { options.twitter && <a href={ options.twitter } target='_blank' rel='nofollow noopener noreferrer'><Twitter /></a> }
          { options.pinterest && <a href={ options.pinterest } target='_blank' rel='nofollow noopener noreferrer'><Pinterest /></a> }
        </div>

      </div>
    )
  }
}

Splash.defaultProps = {
  heading: [],
  homepage: false,
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        properties: allWordpressWpProperty(limit: 3, filter:{acf:{rex_data:{system_listing_state:{ne:"sold"}}}}) {
          edges {
            node {
              title
              slug
              acf {
                rex_data {
                  advert_internet {
                    heading
                  }
                  listing_sale_or_rental
                  price_match_sale
                  price_advertise_as
                  attributes {
                    bedrooms
                    bathrooms
                    total_car_accom
                  }
                  images {
                    url
                  }
                  address {
                    formats {
                      street_name_number_w_suburb
                    }
                  }
                }
              }
            }
          }
        }
        allWordpressAcfOptions {
          edges {
            node {
              options {
                instagram
                facebook
                youtube
                twitter
                pinterest
                linkedin
                banner {
                  title
                  image {
                    localFile {
                      childImageSharp {
                        original {
                          src
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      `}
    render={data => <Splash data={data} {...props} />}
  />
)
