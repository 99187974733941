import React, { Component } from 'react'
import Instafeed from 'react-instafeed'

const instafeedTarget = 'instafeed'

class Instagram extends Component {

  shouldComponentUpdate() {
    return false
  }

  onMouseEnter = () => {
    this.index = 0
    this.interval = setInterval(() => {
      let nodes = document.querySelectorAll('.instagram__item')
      for (let i = 0, l = nodes.length; i < l; i++) {
        nodes[i].style.display = this.index === i ? 'block' : 'none'
      }
      if (++this.index >= nodes.length) this.index = 0
    }, 1000)
  }

  onMouseLeave = () => {
    clearInterval(this.interval)
  }

  render() {
    return (
      <figure>
        <picture id={instafeedTarget} className='instagram' onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <Instafeed
            limit='8'
            ref='instafeed'
            resolution='standard_resolution'
            sortBy='most-recent'
            target={instafeedTarget}
            template='<div class="instagram__item"><a href="{{link}}" target="_blank" rel="noopener noreferrer"><img src={{image}} alt="More Real Estate Instagram"></a></div>'
            userId='10140134694'
            clientId='714f9bbbaa564bb4bf187e478882511d'
            accessToken='10140134694.714f9bb.3f0b4ef6792a484bbbd254b67ec07622'
          />
        </picture>
        <figcaption>
          Rollover for more images
        </figcaption>
      </figure>
    )
  }
}

export default Instagram
