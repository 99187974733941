import React, { Component } from 'react'

class SimpleTitle extends Component {
  render() {
    let image = this.props.background
    return (
      <div className='simple-title' style={{backgroundImage: `url(${image && image.localFile.childImageSharp.original.src})`}}>
        <div className='simple-title__inner'>
          <h2>{ this.props.title }</h2>
        </div>
      </div>
    )
  }
}

export default SimpleTitle
