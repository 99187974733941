import React from 'react'

import Splash from '../components/splash'
import LHSImage from '../components/lhs-image'
import RelatedProperties from '../components/related-properties'
import VideoArea from '../components/video-area'
import Banner from '../components/banner'
import IntroContentHalf from '../components/intro-content-half'
import AboutTeam from '../components/about-team'
import Contact from '../components/contact'
import Search from '../components/search'
import SimpleTitle from '../components/simple-title'
import IconTextCarousel from '../components/icon-text-carousel'
import JoinForm from '../components/join-form'
import BigTestimonial from '../components/big-testimonial'
import TextContent from '../components/text-content'

const renderBlock = (param, el, i, page={}) => {
  let block = {
    "WordPressAcf_landing_splash" : (el, i) => <Splash key={i} search={true} {...el} />,
    "WordPressAcf_small_split_content": (el, i) => <LHSImage key={i} {...el} />,
    "WordPressAcf_3_properties_row": (el, i) => <RelatedProperties key={i} {...el} />,
    "WordPressAcf_video_area": (el, i) => <VideoArea key={i} {...el} />,
    "WordPressAcf_large_more_block": (el, i) => <Banner key={i} {...el} />,
    "WordPressAcf_intro_content_half": (el, i) => <IntroContentHalf key={i} {...el} />,
    "WordPressAcf_about_team": (el, i) => <AboutTeam key={i} {...el} />,
    "WordPressAcf_contact": (el, i) => <Contact key={i} {...el} />,
    "WordPressAcf_search": (el, i) => <Search key={i} context={page.pageContext} {...el} />,
    "WordPressAcf_simple_title": (el, i) => <SimpleTitle key={i} {...el} />,
    "WordPressAcf_icon_text_carousel": (el, i) => <IconTextCarousel key={i} {...el} />,
    "WordPressAcf_join_form": (el, i) => <JoinForm key={i} {...el} />,
    "WordPressAcf_big_testimonial": (el, i) => <BigTestimonial key={i} {...el} />,
    "WordPressAcf_text_content": (el, i) => <TextContent key={i} {...el} />,
  }[param]

  if (!block) return


  return block(el, i)
}

export default renderBlock
