import React, { Component } from 'react'

class IconTextCarousel extends Component {
  render() {
    return (
      <div className='icon-text-carousel'>
        <div className='icon-text-carousel__inner'>
          { this.props.items.map((el, i) => {
            return (
              <div className='icon-text-carousel__item' key={i}>
                <img src={el.icon && el.icon.localFile && el.icon.localFile.childImageSharp.original.src } alt={el.title} />
                <h4>{ el.title }</h4>
                <p>{ el.content }</p>
              </div>
            )
          }) }
        </div>
      </div>
    )
  }
}

export default IconTextCarousel
