import React, { Component } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import TeamMember from './team-member'
import Logo from './logo'

class AboutTeam extends Component {

  state = {
    active: null,
  }

  componentDidMount() {
    let { hash } = window.location
    let { team } = this.props.data
    hash = hash.length && decodeURIComponent(hash.split('#')[1])
    if (hash) {
      let index = team.edges.findIndex((edge, i) => `${edge.node.acf.rex_data.first_name}-${edge.node.acf.rex_data.last_name}` === hash)
      if (typeof index === 'number') this.setState({ active: index })
    }
  }

  _openOverlay = (index) => () => {
    this.setState({ active: index })
  }

  _closeOverlay = () => {
    this.setState({ active: null })
  }

  render() {
    let { team } = this.props.data
    let { active } = this.state

    return (
      <div className='about__team'>
        <div className='about__team__inner'>
          <h2>More — Team</h2>
          <div className='about__team__list'>
            { team.edges.map((el, i) => <TeamMember onClick={this._openOverlay(i)} key={i} {...el.node.acf} />) }
          </div>
        </div>
        <div className={`about__overlay ${active !== null ? 'about__overlay--active' : ''}`}>
          <div className='about__popup'>
            <div className='about__popup__header'>
              <Logo color={'#000000'} />
              <div className='about__popup__header__buttons'>

                <div className='about__popup__close' onClick={this._closeOverlay}>
                  <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g transform="translate(0.000000, -7.000000)" fill="#000000">
                        <g transform="translate(0.000000, 7.000000)">
                          <polygon points="12.6492 0 7.5 5.1492 2.3508 0 0 2.3508 5.1492 7.5 0 12.6492 2.3508 15 7.5 9.7389 12.6492 15 15 12.6492 9.7389 7.5 15 2.3508"></polygon>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <div>Close</div>
                </div>

                <Link to='/contact' className='btn'>Contact — More</Link>

              </div>
            </div>

            <div className='about__popup__body'>
              { active !== null && <TeamMember large={true} {...team.edges[active].node.acf} /> }
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        team: allWordpressWpTeam( sort: {fields: [menu_order], order: ASC}) {
          edges {
            node {
              title
              acf {
                rex_data {
                  first_name
                  last_name
                  email
                  settings {
                    phone_direct
                    phone_mobile
                    email_signature
                    position
                  }
                }
                position
                blurb
                picture {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
                large_picture {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      `}
    render={data => <AboutTeam data={data} {...props} />}
  />
)

