import React, { Component } from 'react'

import { LinkedIn } from '../components/icons'

class BigTestimonial extends Component {
  render() {
    let { image, linkedin } = this.props
    let testimonial = this.props.testimonial && this.props.testimonial.length ? this.props.testimonial[0] : {}
    return (
      <div className='big-testimonial'>
        <div className='big-testimonial__inner'>
          <div className='big-testimonial__text'>
            <svg className='comma' width="15px" height="12px" viewBox="0 0 15 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-369.000000, -2191.000000)" fill="#000000">
                  <g transform="translate(0.000000, 1621.000000)">
                    <path d="M375.44,575.8 L375.44,581.32 L369.28,581.32 L369.28,576.12 C369.28,572.44 371.2,570.8 375.44,570.72 L375.44,573.56 C373.6,573.64 372.68,574.16 372.64,575.8 L375.44,575.8 Z M383.36,575.8 L383.36,581.32 L377.24,581.32 L377.24,576.12 C377.24,572.44 379.2,570.8 383.36,570.72 L383.36,573.56 C381.56,573.64 380.64,574.16 380.64,575.8 L383.36,575.8 Z"></path>
                  </g>
                </g>
              </g>
            </svg>
            <p dangerouslySetInnerHTML={{__html: testimonial.acf.testimony + '”' }} />
            <h4>{ testimonial.acf.name } - { testimonial.acf.customer_type }</h4>
            { linkedin && <a href={ linkedin } target='_blank' rel='nofollow noopener noreferrer'><LinkedIn color={'#000000'} /></a> }
          </div>
          <img src={ image && image.localFile && image.localFile.childImageSharp.original.src } alt={ testimonial.name } />
        </div>
      </div>
    )
  }
}

export default BigTestimonial
