import React, { Component } from 'react'

class TextContent extends Component {
  render() {
    let { title, subtitle, body } = this.props
    return (
      <section className='container text-content'>
        { title && <h1>{ title }</h1> }
        { subtitle && <h2>{ subtitle }</h2> }
        { body && <div dangerouslySetInnerHTML={{__html: body }} /> }
      </section>
    )
  }
}

TextContent.defaultProps = {
  title: '',
  subtitle: '',
  body: '',
}

export default TextContent
