import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'

import SocialLinks from './social-links'
import Map from './map'

class BlockUpdate extends Component {
  shouldComponentUpdate() {
    return false
  }
  render() {
    return (<>{ this.props.children }</>)
  }
}

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends Component {

  state = {
    name: '',
    email: '',
    phone: '',
    type: '',
    message: '',
    preferred_contact: '',
    sellers_ebook: '',
    market_stats: '',
    listing_alerts: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'contact',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    if (this.state.result === 'success')
      return (<p className='contact__success'>Thanks for getting in contact with us.<br /> A member of our team will be in touch soon.</p>)

    return (
      <form {...props}>
        <h2>{ this.props.title }</h2>
        <input type='text' name='name' placeholder='Name' onChange={this.handleChange} required />
        <input type='email' name='email' placeholder='Email' onChange={this.handleChange} required  />
        <input type='text' name='phone' placeholder='Contact Number' onChange={this.handleChange} required  />
        <div className='styled-select'>
          <select name='type' onChange={this.handleChange}>
            <option>Type of Contact</option>
            <option value='appraisal'>Appraisal Enquiry</option>
            <option value='buyer'>Buyer Enquiry</option>
            <option value='general'>General Enquiry</option>
          </select>
        </div>
        <textarea name='message' placeholder='How can we help?' onChange={this.handleChange} />
        <div>
          Preferred type of contact?
          <label>
            <input type='radio' name='preferred_contact' value='phone' onChange={this.handleChange} />
            Phone
          </label>
          <label>
            <input type='radio' name='preferred_contact' value='email' onChange={this.handleChange} />
            Email
          </label>
        </div>
        <div>
          If you would like to receive any of the following free publications to your email please tick<br />
          <label>
            <input type='checkbox' name='sellers_ebook' onChange={this.handleChange} />
            Sellers E-Book
          </label><br />
          <label>
            <input type='checkbox' name='market_stats' onChange={this.handleChange} />
            Monthly Market Stats
          </label><br />
          <label>
            <input type='checkbox' name='listing_alerts' onChange={this.handleChange} />
            New Listing Alerts
          </label>

        </div>
        <button type='submit' className='btn btn--invert'>Send Enquiry</button>
      </form>
    )
  }
}

class Contact extends Component {


  render() {
    let { options } = this.props.data.wordpressAcfOptions
    let { heading_title, image, form_title } = this.props

    let bgSrc = `url(https://picsum.photos/1920/1080/?random=${Math.random()})`
    if (image) bgSrc = `url(${image.localFile.childImageSharp.original.src})`

    return (
      <>
        <div className='contact__header' style={{backgroundImage: bgSrc}}>
          <div className='contact__header__inner'>
            <div className='contact__header__tagline'>More —<br/> { heading_title }</div>
            <SocialLinks />
          </div>
        </div>
        <div className='contact__main'>
          <div className='contact__main__inner contact__main--black'>
            <div className='contact__main__info'>
              <h1>Contact More—Re</h1>

              <h4>Office</h4>
              <a href={`//maps.google.com/?q=${ options.address }`}>— { options.address }</a>

              <h4>Phone</h4>
              <a href={`tel:${ options.phone }`}>— { options.phone }</a>

              <h4>Email</h4>
              <a href={`mailto:${ options.email }`}>— { options.email }</a>

            </div>
            <BlockUpdate>
              <Map lat={ options.latitude } lng={ options.longitude } />
            </BlockUpdate>
          </div>
          <div className='contact__main__inner'>
            <Form title={form_title} />
          </div>
        </div>
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          options {
            address
            phone
            email
            latitude
            longitude
          }
        }
      }
      `}
    render={data => <Contact data={data} {...props} />}
  />
)
