import React, { Component } from 'react'

class JoinForm extends Component {
  render() {
    return (
      <div className='join-form'>
        <div className='join-form__inner'>
          <h3>Interested in finding out more?</h3>
          <form>
            <div className='join-form__wrap'>
              <input type='text' name='name' placeholder='Name' />
              <input type='text' name='phone' placeholder='Contact Number' />
            </div>
            <div className='join-form__wrap'>
              <input type='email' name='email' placeholder='Email Address' />
              <input type='text' name='level' placeholder='Location' />
            </div>

            <textarea name='message' placeholder='How can we help?' />

            <div className='join-from__wrap'>
              <button type='submit' className='btn btn--white'>Send</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default JoinForm
