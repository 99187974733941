import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import PropertySearch from '../components/property-search'
import PropertyCard from '../components/cards/property'

class Search extends Component {
  state = {
    form: {
      type: this.props.context && this.props.context.slug,
      price_min: null,
      price_max: null,
      bedrooms: null,
      bathrooms: null,
      carparks: null,
    }
  }

  render() {
    let { form } = this.state

    this.props.data.allWordpressWpProperty.edges.sort((a, b) => {
      return new Date(b.node.date) - new Date(a.node.date)
    })

    const results = this.props.data.allWordpressWpProperty.edges.map((item, i) => {
      let { rex_data } = item.node.acf

      if (form.type === 'buy' && rex_data.system_listing_state !== 'current') return null
      if (form.type === 'sell' && rex_data.system_listing_state !== 'sold') return null
      if (form.type === 'rent' && rex_data.listing_sale_or_rental !== 'Rental') return null
      if (form.type !== 'rent' && rex_data.listing_sale_or_rental === 'Rental') return null
      if (form.bathrooms && form.bathrooms.value && form.bathrooms.value > parseInt(rex_data.attributes.bathrooms || 0)) return null
      if (form.bedrooms && form.bedrooms.value && form.bedrooms.value > parseInt(rex_data.attributes.bedrooms || 0)) return null
      if (form.carparks && form.carparks.value && form.carparks.value > parseInt(rex_data.attributes.total_car_accom || 0)) return null
      if (form.price_min && form.price_min.value && form.price_min.value > parseInt(rex_data.price_match_sale)) return null
      if (form.price_max && form.price_max.value && form.price_max.value < parseInt(rex_data.price_match_sale)) return null

      let props = {
        key: i,
        type: form.type,

        image: rex_data.images?.length > 0 && rex_data.images[0].url,

        title: rex_data.address.formats.street_name_number_w_suburb,
        link: `/property/${item.node.slug}/`,

        bedrooms: rex_data.attributes.bedrooms,
        bathrooms: rex_data.attributes.bathrooms,
        total_car_accom: rex_data.attributes.total_car_accom,

        price: rex_data.price_match_sale,
      }

      return (<PropertyCard {...props} />)
    }).filter(el => el)

    return (
      <>
        <div className='search'>
          <PropertySearch display='extended' type={this.state.form.type} callback={(state) => this.setState({ form: state })} />
          <div className='search__results'>
            { results }
          </div>
        </div>
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allWordpressWpProperty {
          edges {
            node {
              date
              title
              slug
              acf {
                rex_data {
                  listing_sale_or_rental
                  system_listing_state
                  price_match_sale
                  attributes {
                    bedrooms
                    bathrooms
                    total_car_accom
                  }
                  images {
                    url
                  }
                  address {
                    formats {
                      street_name_number_w_suburb
                    }
                  }
                }
              }
            }
          }
        }
      }
      `}
    render={data => <Search data={data} {...props} />}
  />
)

