import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Instagram from './instagram'

class VideoArea extends Component {
  state = {
    popup: false,
    testimonial: 0,
  }

  render() {
    let { title, content, video, video_poster, testimonials } = this.props

    video_poster = `url(${video_poster && video_poster.localFile.childImageSharp.original.src})`

    let { options } = this.props.data.wordpressAcfOptions

    return (
      <div className='video-area'>

        <div className='video-area__inner video-area__justify'>
          <Fade left distance="40px">
            <div className='video-area__content'>
              <h2>More — <br />{ title }</h2>
              <div dangerouslySetInnerHTML={{__html: content }} />
              <div className='video-area__buttons'>
                { options.youtube && <a href={ options.youtube } className='btn' target='_blank' rel='nofollow noopener noreferrer'>More — Videos</a> }
              </div>
            </div>
          </Fade>
          <div className='video-area__video' style={{backgroundImage: video_poster}} onClick={() => this.setState({ popup: true })}>
            <svg width="61px" height="58px" viewBox="0 0 61 58" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-157.000000, -92.000000)" fill="#FFFFFF">
                  <path d="M187.46354,92 C204.31294,92 218,104.942222 218,120.965333 C218,136.986074 204.31294,150 187.46354,150 C170.61414,150 157,136.986074 157,120.965333 C157,104.944593 170.611648,92 187.46354,92 Z M207,121.464344 L175,104 L175,139 L207,121.464344 Z"></path>
                </g>
              </g>
            </svg>
          </div>
        </div>

        <div className='video-area__inner'>
          <div className='video-area__instafeed'>
            <Instagram />
          </div>
          <Fade bottom distance="40px">
            <div className='video-area__testimonials'>
              <div className='video-area__testimonials__select'>
                { testimonials.map((test, i) => (
                  <div key={i} className={this.state.testimonial === i ? 'active' : ''} onClick={() => this.setState({ testimonial: i })}>
                    <div><span>{ test.testimonial.acf.name }</span></div>
                    <div>{ test.testimonial.acf.location }  —  { test.testimonial.acf.customer_type }</div>
                  </div>
                )) }
              </div>

              <div className='video-area__testimonials__text'>
                <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-369.000000, -2191.000000)" fill="#000000">
                      <g transform="translate(0.000000, 1621.000000)">
                        <path d="M375.44,575.8 L375.44,581.32 L369.28,581.32 L369.28,576.12 C369.28,572.44 371.2,570.8 375.44,570.72 L375.44,573.56 C373.6,573.64 372.68,574.16 372.64,575.8 L375.44,575.8 Z M383.36,575.8 L383.36,581.32 L377.24,581.32 L377.24,576.12 C377.24,572.44 379.2,570.8 383.36,570.72 L383.36,573.56 C381.56,573.64 380.64,574.16 380.64,575.8 L383.36,575.8 Z"></path>
                      </g>
                    </g>
                  </g>
                </svg>

                { testimonials.length && testimonials[this.state.testimonial] &&
                  <p dangerouslySetInnerHTML={{__html: testimonials[this.state.testimonial].testimonial.acf.testimony }} />
                }

              </div>

            </div>
          </Fade>
        </div>
        <div className={`video-area__popup ${this.state.popup ? 'active' : ''}`}>
          { this.state.popup && <div dangerouslySetInnerHTML={{__html: video}} /> }
          <div className='video-area__popup__overlay' onClick={() => this.setState({ popup: false })} />
        </div>
      </div>
    )
  }
}

VideoArea.defaultProps = {
  title: '',
  content: '',
  video: '',
  video_poster: null,
  testimonials: [],
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          options {
            youtube
          }
        }
      }
      `}
    render={data => <VideoArea data={data} {...props} />}
  />
)
